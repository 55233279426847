import { render, staticRenderFns } from "./SalesInvoiceDetails.vue?vue&type=template&id=405de5cb&scoped=true&"
import script from "./SalesInvoiceDetails.vue?vue&type=script&lang=js&"
export * from "./SalesInvoiceDetails.vue?vue&type=script&lang=js&"
import style0 from "./SalesInvoiceDetails.vue?vue&type=style&index=0&id=405de5cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405de5cb",
  null
  
)

export default component.exports