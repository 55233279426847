<template>
	<div class="sales-invoice-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !invoiceNo"
		         v-on:ok="deleteSalesInvoice"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Sales Invoice</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalSalesInvoiceNo" class="font-weight-bold">Invoice No.</label>
					<input type="text" id="modalSalesInvoiceNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': invoiceNoInvalid}"
					       v-model="invoiceNo"
					       v-on:input="invoiceNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveSalesInvoice"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!salesInvoice"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="salesInvoiceNo" class="font-weight-bold">Invoice No.</label>
					<input type="text" id="salesInvoiceNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localSalesInvoice.invoiceNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="salesOrder" class="font-weight-bold">Sales Order</label>
					<input type="text" id="salesOrder" class="form-control position-relative"
					       placeholder="Choose A Sales Order From The Table"
					       v-bind:class="invalidInputs.salesOrder"
					       v-bind:value="localSalesInvoice.orderNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="client" class="font-weight-bold">Client</label>
					<input type="text" id="client" class="form-control position-relative"
					       placeholder="Choose Your Intended Client From The Table"
					       v-bind:class="invalidInputs.client"
					       v-bind:value="localSalesInvoice.clientName" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="status" class="font-weight-bold">Payment Status</label>
					<select id="status" class="form-control position-relative"
					        v-bind:class="invalidInputs.status"
					        v-bind:value="localSalesInvoice.status"
					        v-on:change="updateSalesInvoice('status', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>unpaid</option>
						<option>paid</option>
						<option>overdue</option>
					</select>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localSalesInvoice.items" v-bind:key="index">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localSalesInvoice.items.length === 1"
						        v-on:click="addDeleteItems('delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localSalesInvoice.items.length >= 50"
						        v-on:click="addDeleteItems('add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`item-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`item-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`item-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="currency" class="font-weight-bold">Currency</label>
					<select id="currency" class="form-control position-relative"
					        v-bind:class="invalidInputs.currency"
					        v-bind:value="localSalesInvoice.currency"
					        v-on:change="updateSalesInvoice('currency', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>HKD</option>
						<option>USD</option>
						<option>RMB</option>
						<option>JPY</option>
						<option>CAD</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="serviceFee" class="font-weight-bold">Service Fee</label>
					<input type="text" id="serviceFee" placeholder="Service Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.serviceFee"
					       v-bind:value="localSalesInvoice.serviceFee"
					       v-on:change="updateSalesInvoice('serviceFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="shippingFee" class="font-weight-bold">Shipping Fee</label>
					<input type="text" id="shippingFee" placeholder="Shipping Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.shippingFee"
					       v-bind:value="localSalesInvoice.shippingFee"
					       v-on:change="updateSalesInvoice('shippingFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="otherCharges" class="font-weight-bold">Other Charges</label>
					<input type="text" id="otherCharges" placeholder="Other Charges"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.otherCharges"
					       v-bind:value="localSalesInvoice.otherCharges"
					       v-on:change="updateSalesInvoice('otherCharges', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="tax" class="font-weight-bold">Tax</label>
					<input type="text" id="tax" placeholder="Tax"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.tax"
					       v-bind:value="localSalesInvoice.tax"
					       v-on:change="updateSalesInvoice('tax', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="discount" class="font-weight-bold">Discount</label>
					<input type="text" id="discount" placeholder="Discount"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.discount"
					       v-bind:value="localSalesInvoice.discount"
					       v-on:change="updateSalesInvoice('discount', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="totalAmount" class="font-weight-bold">Total Amount</label>
					<input type="text" id="totalAmount" placeholder="0.00"
					       class="form-control position-relative"
					       v-bind:value="localSalesInvoice.totalAmount" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="issuedBy" class="font-weight-bold">Invoice Issued By</label>
					<input type="text" id="issuedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs Who Issued This Invoice"
					       v-bind:class="invalidInputs.issuedBy"
					       v-bind:value="localSalesInvoice.issuedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="issuedAtDate" class="font-weight-bold text-truncate">Date Issued</label>
						<b-form-datepicker id="issuedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.date"
						                   v-bind:value="localSalesInvoice.issuedAtDT.date"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="issuedAtTime" class="font-weight-bold text-truncate">Time Issued</label>
						<b-form-timepicker id="issuedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.time"
						                   v-bind:value="localSalesInvoice.issuedAtDT.time"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="dueAtDate" class="font-weight-bold text-truncate">Due Date</label>
						<b-form-datepicker id="dueAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.dueAtDT.date"
						                   v-bind:value="localSalesInvoice.dueAtDT.date"
						                   v-on:input="updateDT('dueAt', 'dueAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="dueAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="dueAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.dueAtDT.time"
						                   v-bind:value="localSalesInvoice.dueAtDT.time"
						                   v-on:input="updateDT('dueAt', 'dueAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="paidAtDate" class="font-weight-bold text-truncate">Date Paid</label>
						<b-form-datepicker id="paidAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.paidAtDT.date"
						                   v-bind:value="localSalesInvoice.paidAtDT.date"
						                   v-on:input="updateDT('paidAt', 'paidAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="paidAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="paidAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.paidAtDT.time"
						                   v-bind:value="localSalesInvoice.paidAtDT.time"
						                   v-on:input="updateDT('paidAt', 'paidAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localSalesInvoice.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Invoice Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Invoice Not Yet Saved"
					       v-bind:value="localSalesInvoice.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localSalesInvoice.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localSalesInvoice.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Order Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Invoice Not Yet Created"
					       v-bind:value="localSalesInvoice.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localSalesInvoice.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localSalesInvoice.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
		<div class="table-selects scroll-bar position-relative w-50 h-100 ml-3 rounded">
			<table-select title="Sales Order" filter="orderNo" sort="orderNo"
			              v-bind:order="-1"
			              v-bind:fields="salesOrderFields"
			              v-bind:data-array="salesOrders"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localSalesInvoice.salesOrder]"
			              v-on:row-clicked="selectSalesOrder($event)"
			              v-bind:style="{height: '50% !important'}"></table-select>
			<table-select title="Client" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="clientsFields"
			              v-bind:data-array="clients"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localSalesInvoice.client]"
			              v-on:row-clicked="selectClient($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
			<table-select title="Staff" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="staffsFields"
			              v-bind:data-array="staffs"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localSalesInvoice.issuedBy]"
			              v-on:row-clicked="selectIssuedBy($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent, itemsDifferent, calculateCharges, discountInvalid } from "@/utility/helpers";

export default {
	name: "SalesInvoiceDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		salesInvoiceId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initSalesInvoice();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localSalesInvoice: {
				_id: "",
				invoiceNo: "",
				salesOrder: "",
				client: "",
				status: "",
				items: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				currency: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				totalAmount: "",
				issuedAt: "",
				dueAt: "",
				paidAt: "",
				createdAt: "",
				updatedAt: "",
				issuedBy: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				clientName: "",
				orderNo: "",
				issuedByName: "",
				createdByName: "",
				updatedByName: "",
				dueAtDT: { date: "", time: "" },
				issuedAtDT: { date: "", time: "" },
				paidAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				client: "",
				salesOrder: "",
				status: "",
				currency: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				// can be invalid but not required
				dueAtDT: { date: "", time: "" },
				issuedAtDT: { date: "", time: "" },
				paidAtDT: { date: "", time: "" },
				issuedBy: "",
			},
			invalidItems: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			clientsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Client No.", key: "clientNo", classBinding: ["f-3"] },
				{ display: "Type", key: "type", classBinding: ["f-2"] },
			],
			salesOrderFields: [
				{ display: "Order No.", key: "orderNo", classBinding: ["f-3"] },
				{ display: "Client", key: "client", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
			],
			staffsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Position", key: "position", classBinding: ["f-4"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			invoiceNo: "",
			invoiceNoInvalid: false,
		}
	},
	
	computed: {
		salesInvoice() {
			return this.$store.state.contents.salesInvoices.find(({ _id }) => _id === this.salesInvoiceId);
		},
		
		savable() {
			// fall through pattern
			if (!this.salesInvoice) return true;
			// can safely access properties inside this.salesInvoice due to the first if clause in this function
			if (itemsDifferent(this.localSalesInvoice.items, this.salesInvoice.items)) return true;
			const dtKeys = ["dueAt", "issuedAt", "paidAt"];
			if (dtKeys.some(key => {
				if (dateTimeDifferent(this.localSalesInvoice[key], this.salesInvoice[key])) return true;
			})) return true;
			const invoiceKeys = ["client", "salesOrder", "status", "currency", "serviceFee", "shippingFee", "tax",
				"otherCharges", "discount", "issuedBy", "notes"];
			return invoiceKeys.some(key => this.localSalesInvoice[key] !== this.salesInvoice[key]);
		},
		
		clients() {
			return this.$store.state.contents.clients.map(({ _id, name, clientNo, type }) => {
				return {
					_id: _id,
					name: name,
					clientNo: clientNo,
					type: type
				}
			});
		},
		
		salesOrders() {
			return this.$store.state.contents.salesOrders.map(({ _id, orderNo, client, items, currency, totalAmount }) => {
				const clientFound = this.$store.state.contents.clients.find(({ _id }) => _id === client);
				return {
					_id: _id,
					orderNo: orderNo,
					client: clientFound ? `${clientFound.name} (${clientFound.clientNo})` : "N/A",
					items: items.length,
					currency: currency,
					totalAmount: totalAmount
				}
			});
		},
		
		staffs() {
			return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
				return {
					_id: _id,
					name: `${lastName} ${firstName}, ${preferredName}`,
					staffNo: staffNo,
					gender: gender,
					position: position
				};
			});
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.invoiceNo = "";
				this.invoiceNoInvalid = false;
			}
		},
		
		initSalesInvoice() {
			if (this.salesInvoice) {
				const obj = {...this.salesInvoice};
				obj.items = [];
				this.invalidItems = [];
				this.salesInvoice.items.forEach(item => {
					obj.items.push({...item});
					this.invalidItems.push({ name: "", quantity: "", unitPrice: "" });
				});
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === obj.client);
				const salesOrder = this.$store.state.contents.salesOrders.find(({ _id }) => _id === obj.salesOrder);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.issuedBy);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// createdAt and updatedAt must present in the database, need no nullish check
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				const dtKeys = ["dueAt", "issuedAt", "paidAt"];
				// added properties
				obj.clientName = client ? `${client.name} (${client.clientNo})` : "";
				obj.orderNo = salesOrder ? salesOrder.orderNo : "";
				obj.issuedByName = issuedBy ? `${issuedBy.lastName} ${issuedBy.firstName}, ${issuedBy.preferredName} (${issuedBy.staffNo})` : "";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				dtKeys.forEach(key => {
					if (obj[key]) {
						const atDT = new Date(obj[key]);
						obj[`${key}DT`] = { date: format(atDT, "yyyy-MM-dd"), time: format(atDT, "HH:mm:ss")};
					} else {
						obj[`${key}DT`] = { date: "", time: "" };
					}
				});
				this.localSalesInvoice = obj;
			} else {
				this.localSalesInvoice._id = uuidv4();
				this.localSalesInvoice.invoiceNo = "INV-" + this.localSalesInvoice._id.split("-")[0].toUpperCase() + "-SAL";
			}
		},
		
		addDeleteItems(option, index) {
			if (option === "add") {
				this.localSalesInvoice.items.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
				this.invalidItems.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
			} else {
				this.localSalesInvoice.items.splice(index, 1);
				this.invalidItems.splice(index, 1);
			}
		},
		
		selectClient(client) {
			this.localSalesInvoice.client = client._id;
			this.localSalesInvoice.clientName = `${client.name} (${client.clientNo})`;
			this.invalidInputs.client = "";
		},
		
		selectSalesOrder(order) {
			this.localSalesInvoice.salesOrder = order._id;
			this.localSalesInvoice.orderNo = order.orderNo;
			this.invalidInputs.salesOrder = "";
		},
		
		selectIssuedBy(staff) {
			this.localSalesInvoice.issuedBy = staff._id;
			this.localSalesInvoice.issuedByName = `${staff.name} (${staff.staffNo})`;
			this.invalidInputs.issuedBy = "";
		},
		
		// this method also set discount class to invalid if discountInvalid returns true
		updateTotalAmount() {
			const totalCharges = calculateCharges(this.localSalesInvoice);
			if (discountInvalid(totalCharges, this.localSalesInvoice.discount)) {
				this.invalidInputs.discount = "invalid";
				this.localSalesInvoice.totalAmount = totalCharges.toFixed(2);
			} else {
				this.invalidInputs.discount = "";
				const totalAmount = totalCharges - parseFloat(this.localSalesInvoice.discount);
				this.localSalesInvoice.totalAmount = totalAmount.toFixed(2);
			}
		},
		
		updateSalesInvoice(key, value) {
			this.localSalesInvoice[key] = value;
			switch (key) {
				case "serviceFee":
				case "shippingFee":
				case "tax":
				case "otherCharges":
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localSalesInvoice[key] = parseFloat(value).toFixed(2);
						this.invalidInputs[key] = "";
					} else {
						this.invalidInputs[key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				case "discount":
					if (isNumeric(value) && parseFloat(value) >= 0) this.localSalesInvoice[key] = parseFloat(value).toFixed(2);
					this.updateTotalAmount();
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		updateDT(key, keyDT, dateTime, value) {
			this.localSalesInvoice[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localSalesInvoice[key] = this.localSalesInvoice[keyDT].time ?
					new Date(`${value}T${this.localSalesInvoice[keyDT].time}`).toISOString() : value;
			} else {
				this.localSalesInvoice[key] = this.localSalesInvoice[keyDT].date ?
					new Date(`${this.localSalesInvoice[keyDT].date}T${value}`).toISOString() : value;
			}
		},
		
		updateItems(key, value, index) {
			this.localSalesInvoice.items[index][key] = value;
			switch (key) {
				case "quantity":
					this.invalidItems[index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
					this.updateTotalAmount();
					break;
				case "unitPrice":
					// bind to change event instead of input event
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localSalesInvoice.items[index][key] = parseFloat(value).toFixed(2);
						this.invalidItems[index][key] = "";
					} else {
						this.invalidItems[index][key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				default:
					this.invalidItems[index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const invoiceKeys = ["client", "salesOrder", "status", "currency", "serviceFee", "shippingFee", "tax",
				"otherCharges", "discount"];
			const itemKeys = ["name", "quantity", "unitPrice"];
			invoiceKeys.forEach(key => {
				if (!this.localSalesInvoice[key] ||
					this.localSalesInvoice[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.invalidItems.forEach((item, index) => itemKeys.forEach(key => {
				if (!this.localSalesInvoice.items[index][key] ||
					this.localSalesInvoice.items[index][key].trim().length === 0) item[key] = "invalid"; //object reference
			}));
			if (this.localSalesInvoice.issuedBy || this.localSalesInvoice.issuedAt) {
				if (!this.localSalesInvoice.issuedBy) this.invalidInputs.issuedBy = "invalid";
				if (!this.localSalesInvoice.issuedAtDT.date) this.invalidInputs.issuedAtDT.date = "invalid";
				if (!this.localSalesInvoice.issuedAtDT.time) this.invalidInputs.issuedAtDT.time = "invalid";
			}
			if (this.localSalesInvoice.status === "paid" || this.localSalesInvoice.paidAt) {
				if (this.localSalesInvoice.status !== "paid") this.invalidInputs.status = "invalid";
				if (!this.localSalesInvoice.paidAtDT.date) this.invalidInputs.paidAtDT.date = "invalid";
				if (!this.localSalesInvoice.paidAtDT.time) this.invalidInputs.paidAtDT.time = "invalid";
			}
			if (this.localSalesInvoice.dueAt) {
				if (!this.localSalesInvoice.dueAtDT.date) this.invalidInputs.dueAtDT.date = "invalid";
				if (!this.localSalesInvoice.dueAtDT.time) this.invalidInputs.dueAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key.includes("DT")) {
					return this.invalidInputs[key].date === "invalid" || this.invalidInputs[key].time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			}) || this.invalidItems.some(item => itemKeys.some(key => item[key] === "invalid"));
		},
		
		async saveSalesInvoice() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["clientName", "orderNo", "issuedByName", "createdByName", "updatedByName",
					"dueAtDT", "issuedAtDT", "paidAtDT", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const salesInvoice = {...this.localSalesInvoice};
				if (!salesInvoice.createdAt) {
					salesInvoice.createdAt = now;
					salesInvoice.createdBy = this.$store.state.user.user._id;
				}
				salesInvoice.updatedAt = now;
				salesInvoice.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete salesInvoice[key]);
				await this.$store.dispatch("contents/updateContent", { key: "salesInvoices", value: salesInvoice});
				if (this.salesInvoiceId) {
					this.initSalesInvoice();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/accounting/sales-invoice/${salesInvoice._id}`);
					// need to run initSalesInvoice after route change since salesInvoiceId prop was undefined
					this.initSalesInvoice();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Sales Invoice Created!";
				}
			}
		},
		
		async deleteSalesInvoice(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.invoiceNo !== this.salesInvoice.invoiceNo) this.invoiceNoInvalid = true;
			} else if (this.invoiceNo !== this.salesInvoice.invoiceNo) {
				bvModalEvent.preventDefault();
				this.invoiceNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "salesInvoices", _id: this.salesInvoice._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/accounting/sales-invoice");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/accounting/sales-invoice");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
@import "../../../assets/scss/table_overflow";
</style>